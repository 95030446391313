import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import testimonialsData from '../assets/data/testimonials.json';
import '../assets/styles/Testimonials.css';
import { Carousel } from 'bootstrap'; // Importar directamente desde Bootstrap

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    setTestimonials(testimonialsData);
  }, []);

  useEffect(() => {
    const carouselElement = document.querySelector('#myCarousel');
    const bootstrapCarousel = new Carousel(carouselElement, {
      interval: 5000,
      ride: 'carousel'
    });

    return () => {
      bootstrapCarousel.dispose();
    };
  }, []);

  return (
    <div className="parallax">
      <div id="myCarousel" className="carousel slide mb-6" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {testimonials.map((testimonial, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to={index}
              className={index === 0 ? 'active' : ''}
              aria-current={index === 0 ? 'true' : ''}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {testimonials.map((testimonial, index) => (
            <div key={testimonial.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <div className="testimonial-item">
                <div className="testimonial-content text-center">
                  <h1>{testimonial.headline}</h1>
                  <p className="opacity-75">{testimonial.content}</p>
                  <FontAwesomeIcon icon={faUserCircle} size="3x" className="my-3" />
                  <p><strong>{testimonial.author}</strong></p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
