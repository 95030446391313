import React, { useEffect, useState } from 'react';
import '../assets/styles/Services.css';
import serviceData from '../assets/data/services.json';
import ServiceModal from './ServiceModal';
import serviceDetailsData from '../assets/data/services.json';

const Services = () => {
  const [services, setServices] = useState([]);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setServices(serviceData.slice(0, 3));
    setServiceDetails(serviceDetailsData.slice(0, 3));
  }, []);

  const handleShowModal = (serviceTitle) => {
    const serviceDetail = serviceDetails.find(detail => detail.titulo === serviceTitle);
    if (serviceDetail) {
      setSelectedService(serviceDetail);
      setModalVisible(true);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedService(null);
  };

  return (
    <div className="services-wrapper">
      <div id="servicios" className="container marketing services-section">
        <h1 className="section-title">Servicios</h1>
        <div className="row">
          {services.length > 0 ? (
            services.map((service, index) => (
              <div
                className={`col-lg-4 service-col ${service.resaltar ? 'highlight-service' : ''}`}
                key={index}
              >
                <img
                  className="service-image rounded-circle"
                  width="140"
                  height="140"
                  src={require(`../assets/images/${service.imagen}`)}
                  alt={service.titulo}
                />
                <h2 className="fw-normal">{service.titulo}</h2>
                <p>{service.descripcion}</p>
                <p><a className="btn btn-secondary" href="#" onClick={(e) => { e.preventDefault(); handleShowModal(service.titulo); }}>{service.informacion}</a></p>
                <i className={`service-icon ${service.icono}`}></i>
              </div>
            ))
          ) : (
            <p>Cargando servicios...</p>
          )}
        </div>
      </div>

      {selectedService && (
        <ServiceModal
          show={modalVisible}
          onClose={handleCloseModal}
          serviceDetails={selectedService}
        />
      )}
    </div>
  );
};

export default Services;
