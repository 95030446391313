import React from 'react';
import '../assets/styles/ParallaxSection.css';

const ParallaxSection = () => {
  const handleButtonClick = () => {
    const servicesSection = document.getElementById('servicios');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="parallax">
      <div className="parallax-content">
        <h1>Domina el Arte del Jiu-Jitsu y Fortalece Tu Cuerpo y Mente</h1>
        <button className="cta-button" onClick={handleButtonClick}>
          Explora nuestros servicios
        </button>
      </div>
    </div>
  );
};

export default ParallaxSection;
