import React from 'react';
import '../assets/styles/ServiceModal.css';

const ServiceModal = ({ show, onClose, serviceDetails }) => {
  if (!show || !serviceDetails) return null;

  const handleNavigateToMercadoPago = () => {
    window.location.href = serviceDetails.linkMercadoPago;
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{serviceDetails.titulo}</h2>
          <button className="modal-close" onClick={onClose}>X</button>
        </div>
        <div className="modal-body">
          <img src={require(`../assets/images/${serviceDetails.imagen}`)} alt={serviceDetails.titulo} className="service-image-modal" />
          <h4>Descripción</h4>
          <p>{serviceDetails.descripcion}</p>
          {serviceDetails.precio && (
            <>
              <h4>Precio</h4>
              <p><strong>${serviceDetails.precio}</strong></p>
            </>
          )}
          {serviceDetails.detalles && (
            <div>
              <h4>Antecedentes</h4>
              <p>{serviceDetails.detalles.antecedentes}</p>
              <h4>¿Qué hacemos?</h4>
              <p>{serviceDetails.detalles.queHacemos}</p>
              <h4>¿Por qué lo hacemos?</h4>
              <p>{serviceDetails.detalles.porqueLoHacemos}</p>
              <h4>Otros datos</h4>
              <ul>
                {serviceDetails.detalles.otrosDatos && serviceDetails.detalles.otrosDatos.map((dato, index) => (
                  <li key={index}>{dato}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={onClose}><strong>Cerrar</strong></button>
          <button className="btn btn-primary" onClick={handleNavigateToMercadoPago}><strong>¡Lo quiero!</strong></button>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
