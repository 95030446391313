import React from 'react';
import '../assets/styles/Presentation.css';
import jiuJitsuImage from '../assets/images/jiu-jitsu-image.webp';
import logo1 from '../assets/images/zapataTeamLogo.png';
import logo2 from '../assets/images/sdStudioLogo.webp';

const Presentation = () => {
  const handleButtonClick = () => {
    const phoneNumber = '8111835907';
    const message = 'Hola, me gustaría reservar una clase gratuita.';
    const whatsappUrl = `https://wa.me/52${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div id="presentation" className="presentation-container">
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <div className="affiliations">
              <img src={logo1} alt="Afiliación 1" className="affiliation-logo" />
              <img src={logo2} alt="Afiliación 2" className="affiliation-logo" />
            </div>
            <div className="jiu-jitsu-image">
              <img src={jiuJitsuImage} className="d-block mx-lg-auto img-fluid" alt="Practicantes de Jiu Jitsu" width="700" height="500" loading="lazy" />
            </div>
          </div>
          <div className="col-lg-6">
            <h1 className="studio-name">
              SD STUDIO <span className="brand-highlight">ZONA TEC</span>
            </h1>
            <h2 className="jiu-jitsu-title">
              JIU JITSU <br />
              <span className="brazilian">BRASILEÑO</span>
            </h2>
            <p className="subtitle">Descubre el Arte del Jiu Jitsu y mejora tu bienestar</p>
            <button className="cta-button btn btn-lg px-4 me-md-2" onClick={handleButtonClick}>
              Reserva una clase gratuita
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
