import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Presentation from './components/Presentation';
import ParallaxSection from './components/ParallaxSection';
import Services from './components/Services';
import AboutMe from './components/AboutMe';
import Testimonials from './components/Testimonials';
import Pricing from './components/Pricing';
import TrialClass from './components/TrialClass';
import Footer from './components/Footer';
import Store from './components/Store';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Presentation />
            <ParallaxSection />
            <Services />
            <AboutMe />
            <Testimonials />
            <Pricing />
            <TrialClass />
            <Footer />
          </>
        } />
        <Route path="/store" element={<Store/>} />
      </Routes>
    </Router>
  );
}

export default App;
