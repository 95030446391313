import React from 'react';
import '../assets/styles/Ads.css';

const Ads = () => {
  const handleAdClick = () => {
    window.open('https://www.mindzenstudio.guru', '_blank');
  };

  return (
    <div className="ads-wrapper">
      <div className="ads-content">
        <h2>Descubre Nuestros Masajes Relajantes</h2>
        <p>Visita nuestro sitio para más información y reserva tu sesión hoy mismo.</p>
        <button className="btn btn-primary" onClick={handleAdClick}>Visita nuestro sitio</button>
      </div>
    </div>
  );
};

export default Ads;
