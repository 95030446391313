import React, { useState, useEffect } from 'react';
import '../assets/styles/AboutMe.css';
import aboutMeImage from '../assets/images/aboutMe.webp';
import ServiceModal from './ServiceModal';
import servicesDetails from '../assets/data/aboutMe.json';

const AboutMe = ({ onAddToCart }) => {
  const [showModal, setShowModal] = useState(false);
  const [serviceDetails, setServiceDetails] = useState(null);

  const handleImageClick = () => {
    const biography = servicesDetails.find(service => service.titulo === "Biografía");
    setServiceDetails(biography);
    setShowModal(true);
  };

  return (
    <div id="nosotros" className="container-fluid marketing aboutme-section">
      <div className="row featurette align-items-center justify-content-center">
        <div className="col-12 col-md-7 featurette-text">
          <h2 className="featurette-heading">¡Hola! </h2>
          <h2 className="featurette-heading">
            <span className="text-gray">Soy</span> <span className="text-highlight">Charlie Quiroga.</span>
          </h2>
          <p className="lead">
            Soy Charlie Quiroga, un apasionado instructor de artes marciales con una trayectoria que abarca múltiples disciplinas y años de experiencia en deportes de combate. Comencé mi viaje en las artes marciales en 2005 con la lucha olímpica y el Judo, alcanzando el grado de cinta marrón. En 2008, descubrí mi verdadera pasión por el Jiu-Jitsu Brasileño, donde logré obtener el cinturón negro en 2022.
          </p>
          <p className="lead">
            Actualmente, entreno en SD Studio y me dedico a enseñar Jiu-Jitsu a jóvenes y adultos. Mi objetivo es formar individuos no solo en las técnicas del Jiu-Jitsu, sino también en los valores y la disciplina que las artes marciales inculcan. Me esfuerzo por mantenerme al día con las nuevas tendencias del deporte, integrando estas innovaciones con mi formación marcial tradicional.
          </p>
        </div>
        <div className="col-12 col-md-5 aboutme-image-container">
          <img className="aboutme-image img-fluid mx-auto" src={aboutMeImage} alt="Charlie Quiroga" onClick={handleImageClick} />
        </div>
      </div>
      <ServiceModal
        show={showModal}
        onClose={() => setShowModal(false)}
        serviceDetails={serviceDetails}
        onAddToCart={onAddToCart}
      />
    </div>
  );
};

export default AboutMe
