import React, { useState, useEffect } from 'react';
import productData from '../assets/data/services.json';
import '../assets/styles/Store.css';
import ServiceModal from './ServiceModal';
import Ads from './Ads';

const Store = () => {
  const [products, setProducts] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setProducts(productData);
  }, []);

  const handleShowModal = (product) => {
    setSelectedItem(product);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedItem(null);
  };

  const handleNavigateToMercadoPago = (link) => {
    window.location.href = link;
  };

  return (
    <div className="store-wrapper container my-5">
      <section className="w-100 p-4" style={{ backgroundColor: '#eee', borderRadius: '.5rem' }}>
        <h1 className="store-title mb-4">Nuestra Tienda</h1>
        <div className="row">
          {products.map((product, index) => (
            <div className="col-md-6 col-lg-4 mb-4" key={index}>
              <div className="card text-black h-100">
                <img src={require(`../assets/images/${product.imagen}`)} className="card-img-top" alt={product.titulo} />
                <div className="card-body d-flex flex-column justify-content-between">
                  <div>
                    <div className="text-left mt-1">
                      <h5 className="card-title">{product.titulo}</h5>
                      <h6 className="text-success mb-1 pb-3"><strong>${product.precio}</strong></h6>
                    </div>
                    <div className="text-left">
                      <div className="p-3 mx-n3 mb-4" style={{ backgroundColor: '#eff1f2' }}>
                        <ul className="list-custom">
                          {product.detalles.otrosDatos && product.detalles.otrosDatos.map((dato, index) => (
                            <li key={index}><i className="fa fa-check-circle me-2 check-circle"></i>{dato}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row mt-auto">
                    <button 
                      className="btn btn-secondary flex-fill ms-1" 
                      onClick={() => handleShowModal(product)}
                    >
                      Ver detalles
                    </button>
                    <button 
                      className="btn btn-primary flex-fill me-1" 
                      onClick={() => handleNavigateToMercadoPago(product.linkMercadoPago)}
                    >
                      <strong>Comprar ahora</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {selectedItem && (
        <ServiceModal
          show={modalVisible}
          onClose={handleCloseModal}
          serviceDetails={selectedItem}
        />
      )}
      <Ads />
    </div>
  );
};

export default Store;
