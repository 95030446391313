import React, { useState, useEffect } from 'react';
import pricingData from '../assets/data/pricing.json';
import '../assets/styles/Pricing.css';

const Pricing = () => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    setPlans(pricingData);
  }, []);

  const handleNavigateToMercadoPago = (link) => {
    window.location.href = link;
  };

  return (
    <div id='costos' className="pricing-wrapper">
      <h1 className="pricing-title">Costos</h1>
      <div className="container pricing-section">
        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
          {plans.map((plan, index) => (
            <div className="col" key={index}>
              <div className={`card mb-4 rounded-3 shadow-sm ${plan.type === 'dark' ? 'bg-dark text-white' : 'bg-white text-dark'}`}>
                <div className={`card-header py-3 ${plan.type === 'dark' ? 'bg-transparent-dark' : 'bg-transparent-light'}`}>
                  {plan.subtitle && (
                    <span className={`badge ${plan.type === 'dark' ? 'bg-green' : 'bg-black'}`} style={{ position: 'absolute', top: '-10px', left: '10px' }}>
                      {plan.subtitle}
                    </span>
                  )}
                  <h4 className="my-0 fw-normal">
                    {plan.title}
                    {plan.imageTop && (
                      <img src={require(`../assets/images/${plan.imageTop}`)} className="title-image" alt={plan.title} />
                    )}
                  </h4>
                </div>
                <div className="card-body d-flex flex-column">
                  <ul className="list-unstyled mt-3 mb-4 text-start">
                    {plan.features.map((feature, i) => (
                      <li key={i}>
                        <i className="fa fa-check-circle me-2 check-circle" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  {plan.bottomImage && (
                    <img src={require(`../assets/images/${plan.bottomImage}`)} alt="extra" className="card-bottom-image" />
                  )}
                  {!plan.bottomImage && (
                    <div className="card-bottom-placeholder" />
                  )}
                  <h1 className={`card-title pricing-card-title ${plan.type === 'dark' ? 'text-muted-dark' : 'text-muted-light'}`}>
                    {plan.price}<small className={`text-muted ${plan.type === 'dark' ? 'text-muted-dark' : 'text-muted-light'}`}>{plan.period}</small>
                  </h1>
                  <button
                    type="button"
                    className={`w-100 btn btn-lg mt-auto ${plan.type === 'dark' ? 'btn-outline-light' : 'btn-outline-dark'}`}
                    onClick={() => handleNavigateToMercadoPago(plan.linkMercadoPago)}
                  >
                    {plan.buttonText}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
