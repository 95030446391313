import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/HeaderFooter.css';

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link active" to="/" style={{ color: '#00A7F7' }}>Home</Link>
            </li>
            {isHomePage && (
              <>
                <li className="nav-item">
                <a className="nav-link" href="#nosotros">Nosotros</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#servicios">Servicios</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#costos">Costos</a>
                </li>
                <li className="nav-item">
              <Link className="nav-link" to="/store">
                <div className="cart-icon-wrapper">
                  <i className="fas fa-shopping-cart"></i>
                </div> Tienda
              </Link>
            </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
