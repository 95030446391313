import React from 'react';
import '../assets/styles/HeaderFooter.css';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as TikTokIcon } from '../assets/icons/tiktok.svg';

const Footer = () => {
  return (
    <div className="custom-footer">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div className="col-md-4 d-flex align-items-center">
          <a href="/" className="mb-3 me-2 mb-md-0 text-white text-decoration-none lh-1">
            <svg className="bi" width="30" height="24"><use xlinkHref="#bootstrap"></use></svg>
          </a>
          <span className="mb-3 mb-md-0 text-white">© 2024 SD STUDIO ZONA TEC</span>
        </div>
        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a className="btn-social text-white" href="https://www.instagram.com/sdstudiotec/">
              <InstagramIcon width="24" height="24" className="icon-white" />
            </a>
          </li>
          <li className="ms-3">
            <a className="btn-social text-white" href="https://www.facebook.com/p/Sd-Studio-Monterrey-Sur-100083104702442/">
              <FacebookIcon width="24" height="24" className="icon-white" />
            </a>
          </li>
          <li className="ms-3">
            <a className="btn-social text-white" href="https://www.tiktok.com/@sd.studio.mty.sur">
              <TikTokIcon width="24" height="24" className="icon-white" />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
