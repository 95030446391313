import React from 'react';
import '../assets/styles/TrialClass.css';
import jiuJitsuImage from '../assets/images/trial-class-image.webp';
import patternBackground from '../assets/images/pattern.jpg';

const TrialClass = () => {
  const handleButtonClick = () => {
    const phoneNumber = '8111835907';
    const message = 'Hola, me gustaría reservar una clase de prueba gratuita.';
    const whatsappUrl = `https://wa.me/52${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div id="trial-class" className="trial-class-section-unique">
      <img src={jiuJitsuImage} className="background-image-unique" alt="Practicantes de Jiu Jitsu" />
      <div className="overlay-unique"></div>
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Reserva una clase ¡GRATIS!</h1>
            <p className="lead">Empieza tu viaje hacia la confianza y el bienestar.</p>
            <p className="lead">Explora el mundo del Jiu-Jitsu con una clase de prueba gratuita. Aprovecha la oportunidad para aprender de los mejores, en un ambiente seguro y acogedor. Ideal para niños y adultos que buscan fortalecer su cuerpo y mente.</p>
          </div>
          <div className="col-10 col-sm-8 col-lg-6 text-center">
            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              <button type="button" className="btn btn-primary btn-lg px-4 me-md-2 trial-button-unique" onClick={handleButtonClick}><strong>Reserva tu clase de prueba</strong></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialClass;
